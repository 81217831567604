/**
 * video embed
 * @param  {Object}  Config object
 * @requires [lib/util.js,lib/modal.js,lib/modal-get.js,lib/modal-clear.js]
 */

import hasVideoGotAudio from './has-video-got-audio';

SDG.videoEmbed = function (opts) {
  const config = {
    id: 'vid',
    image: '.js-vid-image',
    class: {
      embed: 'vid__embed',
      fade_out: 'is-fading-out',
      has_embed: 'has-embed',
      ir: 'ir ir--vid',
    },
    video_url: {
      youtube: 'https://www.youtube.com/embed/',
      vimeo: 'https://player.vimeo.com/video/',
    },
    show_in_modal: false,
    modal: null, // keys: id, modifier, overlay
  };
  const c = __.extend(config, opts);

  // globals
  const video = document.getElementById(c.id);

  /**
   * init
   * @return {Function}
   */
  function init() {
    bindEvents();
  }

  /**
   * bind events
   * @return {Function}
   */
  function bindEvents() {
    if (video) {
      if (c.show_in_modal) {
        video.addEventListener('click', generateModal, false);
      } else {
        video.addEventListener('click', insertVideo, false);
      }
    }
  }

  /**
   * unbind events
   * @return {Function}
   */
  function unbindEvents() {
    if (c.show_in_modal) {
      video.removeEventListener('click', generateModal, false);
    } else {
      video.removeEventListener('click', insertVideo, false);
    }
  }

  /**
   * insert video
   * @return {Function}
   */
  function insertVideo() {
    const videoId = this.getAttribute('data-video-id');
    const videoPlayer = this.getAttribute('data-video-player');
    const videoImage = video.querySelector(c.image);
    const videoEmbed = buildEmbed(videoId, videoPlayer);

    // unbind events
    unbindEvents();

    // transition image-to-video
    __.transition({
      before: () => {
        // add fade-out class to image
        __.addClass(videoImage, c.class.fade_out);
      },
      after: () => {
        // hide image
        videoImage.style.display = 'none';

        // remove fade-out class from image
        __.removeClass(videoImage, c.class.fade_out);

        // prepend video embed to video container
        video.insertBefore(videoEmbed, videoImage);
      },
    });
  }

  /**
   * video player chocie
   * @param {String} videoPlayer
   * @return {Function}
   */
  function videoPlayerChoice(videoPlayer) {
    let videoUrlPlayer;

    switch (videoPlayer) {
      case 'vimeo':
        videoUrlPlayer = c.video_url.vimeo;
        break;
      default:
        videoUrlPlayer = c.video_url.youtube;
        break;
    }
    return videoUrlPlayer;
  }

  /**
   * build embed
   * @param  {String} videoId     Id of the video on youtube or vimeo
   * @param  {String} videoPlayer Type of player, youtube or vimeo. Default is youtube
   * @return {String}             Html for the iframe embed
   */
  function buildEmbed(videoId, videoPlayer) {
    const videoChoice = videoPlayerChoice(videoPlayer);
    const videoUrl = `${videoChoice}${videoId}?autoplay=1&rel=0`;
    const videoEmbed = document.createElement('div');
    const videoIframe = `<iframe width="1280" height="720" src="${videoUrl}" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>`;

    // set class name
    videoEmbed.className = `${c.class.embed} ${c.class.ir}`;

    // append iframe html to embed div
    videoEmbed.innerHTML = videoIframe;

    return videoEmbed;
  }

  /**
   * generate modal
   * @return {Function}
   */
  function generateModal() {
    const videoId = this.getAttribute('data-video-id');
    const videoPlayer = this.getAttribute('data-video-player');
    const videoChoice = videoPlayerChoice(videoPlayer);
    const videoUrl = `${videoChoice}${videoId}?autoplay=1&rel=0`;
    const html = `<div class="vid__embed ir ir--vid">
        <iframe width="1280" height="720" src="${videoUrl}" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
      </div>`;

    let md;
    let modalWrap;

    if (c.modal) {
      md = SDG.Modal.getHtml({
        modal: c.modal.id,
        modifier: c.modal.modifier,
        overlay: c.modal.overlay,
        html,
      }).render();

      modalWrap = document.createElement('div');
      modalWrap.innerHTML = md;
      document.body.appendChild(modalWrap);

      setTimeout(() => initVideo, 20);
    }

    function initVideo() {
      const videoModal = SDG.Modal({
        dom: {
          modal_id: c.modal.id,
        },
        cb: {
          close: attachCloseEvent,
        },
      });
      videoModal.init();
      videoModal.open(c.modal.id);
    }

    function attachCloseEvent() {
      setTimeout(() => {
        const clear = SDG.Modal.clear({
          modal: c.modal.id,
          overlay: c.modal.overlay,
        });
        clear.init();
      }, 500);
    }
  }

  return {
    init,
  };
};

/**
 * video HTML
 * @param  {Object}  Config object
 * @requires [lib/util.js]
 */
SDG.videoHTML = function (opts) {
  const config = {
    dom: {
      videoWrapper: 'js-video',
      videoControls: 'video__controls',
      playBtnToggle: 'video__control-play--toggle',
      soundBtnToggle: 'video__control-sound--toggle',
      altPlayBtn: 'js-alt-play',
      controlsWrapper: 'js-controls',
    },
    cls: {
      hidden: 'is-hidden',
      hide: 'hide',
      init: 'is-initialized',
    },
  };

  const c = __.extend(config, opts);
  const $videoWrapper = document.querySelectorAll(`.${c.dom.videoWrapper}:not(.${c.cls.init})`);
  const $playBtnToggle = document.querySelectorAll(`.${c.dom.playBtnToggle}`);
  const $altPlayBtn = document.querySelectorAll(`.${c.dom.altPlayBtn}`);
  const $soundBtnToggle = document.querySelectorAll(`.${c.dom.soundBtnToggle}`);
  let $playBtn;
  let $soundBtn;

  /**
   * Init
   */
  function init() {
    if (!$videoWrapper) return;
    addEvents();
  }

  /**
   * Add Events
   */
  function addEvents() {
    $videoWrapper.forEach(async ($video) => {
      __.addClass($video, c.cls.init);

      // hide sound UI if video does not have audio
      const videoUrl = $video.querySelector('source')?.getAttribute('src');
      if (videoUrl) {
        const hasAudio = await hasVideoGotAudio(videoUrl);
        if (!hasAudio) {
          const $soundBtn = $video.querySelector(`.${c.dom.soundBtnToggle}`);
          if ($soundBtn) {
            $soundBtn.classList.add('hide');
          }
        }
      }
    });

    $playBtnToggle.forEach(($btn) => {
      $btn.addEventListener('click', (e) => {
        handlePlayToggle(e);
      });
    });

    $altPlayBtn.forEach(($btn) => {
      $btn.addEventListener('click', (e) => {
        handlePlayToggle(e, true);
      });
    });

    $soundBtnToggle.forEach(($btn) => {
      $btn.addEventListener('click', (e) => {
        handleSoundToggle(e);
      });
    });
  }

  /**
   * Handle Play Toggle
   * @param {Object} e - change event
   */
  function handlePlayToggle(e, isAlt = false) {
    e.preventDefault();
    const $videoParents = __.parents(e.currentTarget);
    const $videoParent = $videoParents
      ? $videoParents.find(($parent) => $parent.classList.contains(c.dom.videoWrapper))
      : null;
    const $video = $videoParent
      ? $videoParent.querySelector('video')
      : null;

    e.currentTarget.classList.toggle('playing');
    $playBtn = $videoParent
      ? $videoParent.querySelector(`.${c.dom.playBtnToggle}`)
      : null;

    if ($video) {
      if (e.currentTarget.classList.contains('playing')) {
        $video.play();
        $playBtn.setAttribute('aria-label', SDG?.Data?.videoStrings?.pause);
        if (isAlt) {
          $playBtn.parentElement.classList.toggle(c.cls.hide);
          $playBtn.classList.toggle('playing');
        }
      } else {
        $video.pause();
        $playBtn.setAttribute('aria-label', SDG?.Data?.videoStrings?.play);
      }
    }
  }

  /**
   * Handle Sound Toggle
   * @param {Object} e - change event
   */
  function handleSoundToggle(e) {
    e.preventDefault();
    e.currentTarget.classList.toggle('sound');
    const $videoParents = __.parents(e.currentTarget);
    const $videoParent = $videoParents
      ? $videoParents.find(($parent) => $parent.classList.contains(c.dom.videoWrapper))
      : null;
    const $video = $videoParent
      ? $videoParent.querySelector('video')
      : null;
    $soundBtn = $videoParent
      ? $videoParent.querySelector(`.${c.dom.soundBtnToggle}`)
      : null;

    if ($video) {
      if (e.currentTarget.classList.contains('sound')) {
        $video.muted = false;
        $soundBtn.setAttribute('aria-label', SDG?.Data?.videoStrings?.mute);
      } else {
        $video.muted = true;
        $soundBtn.setAttribute('aria-label', SDG?.Data?.videoStrings?.unmute);
      }
    }
  }

  return init();
};
